<template>
  <v-container fluid class="pa-4">
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-card>
          <h1 class="text-center pt-4">
            {{ $t('members.verifyGroupEmailInvitation') }}
          </h1>
          <v-card-text class="text-center">
            <v-progress-circular
              :size="50"
              :width="7"
              color="app-blue"
              indeterminate
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { HAS_ACCOUNT, LOGOUT } from '../../store/auth/types';
import { ACCEPT_GROUP_EMAIL_INVITATION } from '../../store/group-members/types';

export default {
  name: 'verify-group-email-invitation',
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated'])
  },
  data() {
    return {
      verify: true,
      token: '',
      email: ''
    };
  },
  async created() {
    this.token = this.$route.query.grt;
    try {
      const result = await this.$store.dispatch(HAS_ACCOUNT, {
        token: this.token
      });
      this.email = result.email;

      if (result.hasAccount) {
        this.manageWhenUserHasAccount();
      } else {
        this.manageWhenUserHasNoAccount();
      }
    } catch (err) {
      this.handleHasAccountError();
    }
  },
  methods: {
    async manageWhenUserHasAccount() {
      // check if user is logged in with the same email address than the email address in the token
      this.acceptGroupEmailInvitation();

      if (this.isAuthenticated && this.currentUser.email === this.email) {
        this.$router.push({ path: '/groups' });
      } else {
        this.$router.push({ path: '/login' });
      }
    },
    async manageWhenUserHasNoAccount() {
      if (this.isAuthenticated) {
        await this.$store.dispatch(LOGOUT);
      }

      this.$router.push({
        path: '/group-sign-up',
        query: { grt: this.token, email: this.email }
      });
    },
    async acceptGroupEmailInvitation() {
      await this.$store.dispatch(ACCEPT_GROUP_EMAIL_INVITATION, {
        invitationId: this.$route.query.invitationId,
        token: this.token
      });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('members.successfullyJoinedGroup')
      });
    },
    handleHasAccountError() {
      if (this.isAuthenticated) {
        this.$router.push({ path: '/dashboard' });
      } else {
        this.$router.push({ path: '/login' });
      }
    }
  }
};
</script>
